<template>
  <div class="global-layout">
    <v-main>
      <v-container fluid class="h-full">
        <router-view :key="$route.path" />
      </v-container>
      <Notification/>
      <NotificationDialog/>
    </v-main>
  </div>
</template>
<script>
import Notification from '@/components/Notification'
import NotificationDialog from '@/components/NotificationDialog'

export default {
  name: 'GlobalLayout',
  components: {
    Notification,
    NotificationDialog
  },
  data () {
    return {}
  },
  created () {
    const recaptcha = this.$recaptchaInstance
    if (recaptcha) { recaptcha.hideBadge() }
  }
}
</script>

<style lang="scss" scoped>
.global-layout {
  background-color: var(--v-background-base);
}
</style>
